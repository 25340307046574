import React from 'react'
import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom'
import ButtonCircle from '../ButtonCircle'

function ButtonReset({ history }: RouteComponentProps) {
  function reset() {
    history.push('/')
  }

  return (
    <ButtonCircle onClick={() => reset()} icon="refresh" />
  )
}

export default withRouter(ButtonReset)

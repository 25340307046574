import React from 'react'
import logoImg from 'lib/images/logo.svg'
import style from './style.css'

export default function Logo() {
  return (
    <div className={style.logo}>
      <img src={logoImg} />
      <a
        className={style.link}
        data-show-count="false"
        href="https://twitter.com/intent/follow?screen_name=infiniteloopco"
      >
        <span>created by </span>
        @infiniteloopco
      </a>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'

import Logo from '../Logo'
import Loading from '../Loading'

import style from './style.css'

export default function Browser() {
  const [loading, setLoading] = useState(true)

  useEffect(() => setLoading(false))

  return (
    <div>
      <Logo />
      <CSSTransition
        in={loading}
        classNames="fade"
        timeout={300}
        unmountOnExit
      >
        <Loading />
      </CSSTransition>

      <div className={style.wrap}>
        <div className={style.item}>
          <p>
            GifStudio is only accessible on certain devices
            due to limitations in our ability to access
            camera data from a web browser.
          </p>

          <p>
            Please visit GifStudio on a computer with Google
            Chrome, or an iPhone with Safari for the best
            experience.
          </p>
        </div>
      </div>
    </div>
  )
}

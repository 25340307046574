import React from 'react'
import cn from 'classnames'
import style from './style.css'

interface Props {
  count: number
}

export default function CountOverlay({ count }: Props) {
  const wrap = cn(style.count, count > 3 && style.hidden)
  const text = count === 0 ? '' : count

  return (
    <div className={wrap}>
      <div className="count-number vc-item">{text}</div>
    </div>
  )
}

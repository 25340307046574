import React from 'react'
import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom'
import Spinner from '../Spinner'

import gifshot from 'gifshot'
import style from './style.css'

interface GifShotImg {
  error: boolean
  errorCode: string
  errorMsg: string
  image: string
}

interface Props extends RouteComponentProps {
  images: string[]
}

class GridPage extends React.Component<Props, {}> {
  componentDidMount() {
    this.createGif()
  }

  createGif() {
    const { images, history } = this.props

    gifshot.createGIF(
      {
        images,
        numWorkers: 10,
        gifWidth: 640,
        gifHeight: 360,
      },
      (obj: GifShotImg) => {
        if (!obj.error) {
          const { image } = obj

          const imageId = Date.now()

          const i = new Image()
          i.src = image

          // preload the gif so it loads instantly
          // when we get to GifPage
          i.onload = () => {
            history.push({
              pathname: `/${imageId}`,
              state: {
                gifshot: image,
              },
            })
          }
        }
      },
    )
  }

  render() {
    const { images } = this.props

    const snapshots = images.map((src, i) => (
      <div
        key={i}
        style={{ backgroundImage: `url(${src})` }}
        className={style.grid_img}
      />
    ))

    return (
      <>
        <div className={style.grid}>{snapshots}</div>

        <div className={style.center}>
          <h1 className={style.text}>
            Creating Gif <Spinner />
          </h1>
        </div>
      </>
    )
  }
}

export default withRouter(GridPage)

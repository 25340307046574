import React from 'react'
import { CSSTransition } from 'react-transition-group'

import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom'
import cn from 'classnames'

import Logo from '../Logo'
import ButtonBar from '../ButtonBar'
import ButtonStart from '../ButtonStart'
import SocialButtons from '../SocialButtons'
import VideoFilters from '../VideoFilters'
import VideoCam from '../VideoCam'
import VideoCanvas from '../VideoCanvas'
import CountOverlay from '../CountOverlay'

import style from './style.css'

const snapCount = 16

interface Props extends RouteComponentProps {
  webcamLoaded: () => void
  imagesCaptured: (images: string[]) => void
}

interface State {
  images: string[]
  filters: string[]
  count: number
  flashing: boolean
  capturingImages: boolean
}

class VideoPage extends React.Component<Props, State> {
  state = {
    images: [] as string[],
    filters: [] as string[],
    count: 4,
    flashing: false,
    capturingImages: false,
  }

  startCapturing = () => {
    this.setState({ capturingImages: true }, () =>
      this.countDown(),
    )
  }

  countDown() {
    const { count } = this.state

    this.setState((prev) => ({
      count: prev.count - 1,
    }))

    // when count hits 0, start capturing images
    if (count === 0) {
      // reset count to 4
      this.setState(
        {
          count: 4,
        },
        () => this.captureImage(),
      )
    } else {
      setTimeout(() => this.countDown(), 600)
    }
  }

  flash() {
    this.setState({ flashing: true }, () => {
      setTimeout(
        () => this.setState({ flashing: false }),
        10,
      )
    })
  }

  captureImage() {
    const { images } = this.state

    // if 16 images, time to stitch them into a gif
    if (images.length === snapCount) {
      this.props.imagesCaptured(images)
    }

    // if < 16 images then take another
    if (images.length < snapCount) {
      this.flash()

      const canvas = document.querySelector(
        '.canvas',
      ) as HTMLCanvasElement
      const snapshot = canvas.toDataURL('image/png')

      images.push(snapshot)

      this.setState({
        images,
      })

      // wait 100ms then capture another image
      setTimeout(() => this.captureImage(), 100)
    }
  }

  toggleFilter(current: string) {
    const { filters } = this.state

    const isCurrent = filters.includes(current)

    // remove current to create filtered array
    const filtered = filters.filter((i) => i !== current)

    // if current, replace with filtered array,
    // otherwise append to arry of filters
    const updatedFilters = isCurrent
      ? filtered
      : [...filters, current]

    this.setState({ filters: updatedFilters })
  }

  render() {
    const {
      filters,
      flashing,
      count,
      capturingImages,
    } = this.state
    const { webcamLoaded } = this.props

    const flashClass = cn(
      style.flash,
      flashing && style.show,
    )

    return (
      <>
        <CSSTransition
          in={!capturingImages}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <Logo />
        </CSSTransition>

        <div className={flashClass} />
        <CountOverlay count={count} />

        <VideoCam videoLoaded={webcamLoaded} />
        <VideoCanvas filters={filters} />

        <CSSTransition
          in={!capturingImages}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <ButtonBar>
            <SocialButtons />
            <ButtonStart
              handleClick={() => this.startCapturing()}
            />
            <VideoFilters
              filters={filters}
              toggleFilter={(cur) => this.toggleFilter(cur)}
            />
          </ButtonBar>
        </CSSTransition>
      </>
    )
  }
}

export default withRouter(VideoPage)

// @ts-nocheck
import nebulaImg from 'lib/images/nebula.jpg'

const img = new Image()
img.src = nebulaImg

const filterMap = {
  tv: null,
  invert: null,
  sketch: null,
  emboss: null,
  warp: null,
  text: null,
  pixels: null,
  space: null,
  mirror: null,
  albert: null,
  ozzy: null,
  prism: null,
}

export function startCanvas(filters: string[]) {
  // if previous Seriously exists, \
  // destroy it before making a new one
  if (window.SL) {
    window.SL.destroy()
  }

  const SL = new Seriously()
  window.SL = SL

  const liveVideo = SL.source('.video')
  const target = SL.target('.canvas')

  // if no filters in array then use live video
  if (filters.length === 0) {
    target.source = liveVideo
    SL.go()

    // if filters then use fx
  } else {
    // tv
    const tvGroup = {
      glitch: SL.effect('tvglitch'),
      bw: SL.effect('hue-saturation'),
    }

    tvGroup.bw.source = liveVideo
    tvGroup.bw.saturation = -1

    tvGroup.glitch.distortion = 0.01
    tvGroup.glitch.verticalSync = 0
    tvGroup.glitch.scanlines = 0.22
    tvGroup.glitch.lineSync = 0.03
    tvGroup.glitch.frameSharpness = 10.67
    tvGroup.glitch.frameLimit = 0.3644
    tvGroup.glitch.bars = 0.15
    tvGroup.glitch.source = tvGroup.bw

    filterMap.tv = tvGroup.glitch

    // invert
    const invert = SL.effect('invert')
    invert.source = liveVideo
    filterMap.invert = invert

    // sketch
    const sketch = SL.effect('sketch')
    sketch.source = liveVideo
    filterMap.sketch = sketch

    // emboss
    const emboss = SL.effect('emboss')
    emboss.source = liveVideo
    emboss.amount = 4
    filterMap.emboss = emboss

    // warp
    const warp = SL.effect('panorama')
    warp.width = liveVideo.width
    warp.height = liveVideo.height
    warp.source = liveVideo
    warp.fov = 150
    filterMap.warp = warp

    // text
    const text = SL.effect('ascii')
    const bright = SL.effect('brightness-contrast')
    bright.source = liveVideo
    bright.brightness = 1.45
    text.source = bright
    filterMap.text = text

    // pixels
    const pixels = SL.effect('pixelate')
    pixels.source = liveVideo
    filterMap.pixels = pixels

    // space
    const water = SL.transform('reformat')
    water.source = img
    water.mode = 'cover'
    water.width = target.width
    water.height = target.height

    const chroma = SL.effect('chroma')
    chroma.weight = 1.32
    chroma.balance = 0
    chroma.screen = '#DFD7EB'
    chroma.clipWhite = 0.85
    chroma.clipBlack = 0.5
    chroma.source = liveVideo

    const universeGroup = {
      blend: SL.effect('blend'),
      ripple: SL.effect('ripple'),
      blue: SL.effect('tone'),
    }

    universeGroup.blend.top = chroma
    universeGroup.blend.bottom = water
    universeGroup.blend.opacity = 0.5

    universeGroup.blue.light = 'rgb(140, 140, 255)'
    universeGroup.blue.dark = 'rgb(0, 0, 50)'
    universeGroup.blue.toned = 0.3

    universeGroup.blue.source = universeGroup.blend
    universeGroup.ripple.source = universeGroup.blend

    const space = universeGroup.ripple
    filterMap.space = space

    // mirror
    const mirror = SL.effect('kaleidoscope')
    mirror.source = liveVideo
    mirror.segments = 1
    filterMap.mirror = mirror

    // albert
    const hue = SL.effect('hue-saturation')
    hue.source = liveVideo
    hue.saturation = 0.5

    const distort = SL.effect('ripple')
    distort.source = hue
    distort.distortion = 2.5
    filterMap.distort = distort

    const albert = SL.effect('ripple')
    albert.source = distort
    filterMap.albert = albert

    // ozzy
    const ozzy = SL.transform('reformat')
    const shake = SL.transform('camerashake')
    const accumulator = SL.effect('accumulator')
    ozzy.mode = 'cover'

    shake.source = liveVideo
    shake.rotation = 4
    shake.frequency = 0.12
    shake.amplitudeX = 4
    shake.amplitudeY = 8
    shake.preScale = 0
    accumulator.opacity = 0.05
    accumulator.source = shake
    ozzy.source = accumulator

    filterMap.ozzy = ozzy

    // prism
    const prism = SL.effect('kaleidoscope')
    prism.source = liveVideo
    prism.segments = 3
    filterMap.prism = prism

    filters.forEach((filter, i) => {
      target.source = filterMap[filter]

      if (i > 0) {
        filterMap[filter].source = filterMap[filters[i - 1]]
      }

      if (i > 1) {
        filterMap[filters[i - 1]].source =
          filterMap[filters[i - 2]]
      }
    })

    SL.go((time) => {
      const slow = Math.abs(Math.sin(time / 10000))
      const med = Math.abs(Math.sin(time / 5000))
      const fast = Math.abs(Math.sin(time / 2500))

      pixels.pixelSize = 2 + fast * 5

      tvGroup.glitch.time = time / 1000

      prism.segments = 2 + slow * 3
      prism.offset = 0 + fast * 0.25

      hue.hue = med

      albert.wave = 100 + slow * 1000
      albert.distortion = 0.25 + fast * 3

      distort.wave = 0.83 + med * 0.75

      shake.time = time / 200

      universeGroup.ripple.wave = Math.abs(
        Math.sin(time / 1000 / 4),
      )
      universeGroup.ripple.distortion =
        Math.abs(Math.sin(time / 1000 / 4.5)) * 2
    })
  }

  return SL
}

import React from 'react'
import style from './style.css'

export default function Spinner() {
  return (
    <span className={style.spinner}>
      <span />
      <span />
      <span />
    </span>
  )
}

import React, { useRef, useEffect } from 'react'
import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom'

import style from './style.css'

interface Props extends RouteComponentProps {
  videoLoaded: () => void
}

function VideoCam({ videoLoaded, history }: Props) {
  const videoEl = useRef(document.createElement('video'))

  const getMediaStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(
        { video: { facingMode: 'user' } },
      )

      const v = videoEl.current

      if (v) {
        v.srcObject = stream
        v.play()

        videoLoaded()
      }
    } catch (err) {
      console.error('stream error:', err)

      if (err.name === 'DevicesNotFoundError') {
        alert(
          'Your webcam was not found, please connect it and reload the page.',
        )
      }
    }
  }

  useEffect(() => {
    if (navigator.mediaDevices) {
      getMediaStream()
    } else {
      console.error('error', navigator)
      history.push('/browser')
    }
  }, [])

  // const ios = isMobile()
  // const width = ios ? 320 : 640
  // const height = ios ? 640 : 360

  return (
    <video
      className={`${style.video} video`}
      ref={videoEl}
      width={1280}
      height={720}
      autoPlay
      playsInline
      muted
    />
  )
}

export default withRouter(VideoCam)

// @ts-nocheck
export function setupTwitter() {
  return (window.twttr = (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]
    const t = window.twttr || {}
    if (d.getElementById(id)) {
      return t
    }
    const js = d.createElement(s)
    js.id = id
    js.src = 'https://platform.twitter.com/widgets.js'
    fjs.parentNode.insertBefore(js, fjs)
    t._e = []

    t.ready = function (f) {
      t._e.push(f)
    }

    return t
  })(document, 'script', 'twitter-wjs'))
}

export function setupFacebook() {
  return (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    const js = d.createElement(s)
    js.id = id
    js.src =
      '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
}

import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { RouteComponentProps } from 'react-router-dom'

import S3, { PutObjectRequest } from 'aws-sdk/clients/s3'
// @ts-ignore
import { config } from 'aws-sdk/lib/core'
import s3config from 'lib/config/iam_config.json'

import Logo from '../Logo'
import Loading from '../Loading'

import ButtonCopy from '../ButtonCopy'
import ButtonIcon from '../ButtonIcon'
import ButtonDownload from '../ButtonDownload'
import ButtonReset from '../ButtonReset'
import ButtonBar from '../ButtonBar'
import SocialButtons from '../SocialButtons'

import style from './style.css'

interface MatchProps {
  id: string
}

interface LocationProps extends Location {
  gifshot?: string
}

type Props = RouteComponentProps<
  MatchProps,
  {},
  LocationProps
>

interface State {
  src: string
  imgLoaded: boolean
  linkCreated: boolean
}

export default class GifPage extends React.Component<
  Props,
  State
> {
  state = {
    src: null as string,
    imgLoaded: false,
    linkCreated: false,
  }

  componentDidMount() {
    console.log('gifpage')

    this.setup()
  }

  setup() {
    const { match, location } = this.props

    const { id } = match.params
    const gifshot = location?.state?.gifshot

    const imageUrl = `https://s3.amazonaws.com/www.gifstudio.me/generated/${id}.gif`

    // if preloaded image is present, just show the page instantly
    if (gifshot) {
      console.log('found gifshotImg')

      this.setState(
        {
          src: gifshot,
          imgLoaded: true,
        },
        () => this.uploadImage(gifshot),
      )
    } else {
      console.log('loading image from aws')
      this.loadImage(imageUrl)
    }
  }

  loadImage = (src: string) => {
    // preload main image
    const i = new Image()

    i.onload = () => {
      this.setState({
        src,
        imgLoaded: true,
        linkCreated: true,
      })
    }

    i.src = src
  }

  uploadImage = (image: string) => {
    console.log('upload aws')

    const { match, history } = this.props
    const { id } = match.params

    config.credentials = s3config

    const s3 = new S3({
      params: {
        Bucket: 'www.gifstudio.me',
      },
    })

    const decode = Buffer.from(
      image.replace(/^data:image\/\w+;base64,/, ''),
      'base64',
    )

    const data: PutObjectRequest = {
      Key: `generated/${id}.gif`,
      Body: decode,
      ContentEncoding: 'base64',
      ContentType: 'image/gif',
      ACL: 'public-read',
      Bucket: 'www.gifstudio.me',
    }

    s3.putObject(data, (err, data) => {
      if (err) {
        console.error('Error uploading data: ', data, err)

        alert(
          'Sorry, there was an error processing your gif',
        )

        history.push('/')
      } else {
        console.log('gif uploaded', data)
        this.setState({ linkCreated: true }, () =>
          history.replace(`/${id}`),
        )
      }
    })
  }

  render() {
    const { src, imgLoaded, linkCreated } = this.state
    const { id } = this.props.match.params

    const copyBtn = linkCreated ? (
      <ButtonCopy copyText={window.location.href} />
    ) : (
      <ButtonIcon
        icon="link"
        text="Creating Link"
        loading
      />
    )

    return (
      <>
        <CSSTransition
          in={!imgLoaded}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <Loading />
        </CSSTransition>

        <CSSTransition
          in={imgLoaded}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <Logo />
        </CSSTransition>

        <CSSTransition
          in={imgLoaded}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <img className={style.gif} src={src} />
        </CSSTransition>

        <CSSTransition
          in={imgLoaded}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <ButtonBar>
            <SocialButtons gifPage />
            <ButtonReset />

            <div className={style.actions}>
              {copyBtn}
              <ButtonDownload id={id} />
            </div>
          </ButtonBar>
        </CSSTransition>
      </>
    )
  }
}

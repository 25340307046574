import React from 'react'
import cn from 'classnames'
import style from './style.css'

const filtersList = [
  'tv',
  'invert',
  'sketch',
  'emboss',
  'warp',
  'text',
  'pixels',
  'space',
  'mirror',
  'albert',
  'ozzy',
  'prism',
]

interface Props {
  filters: string[]
  toggleFilter: (filter: string) => void
}

export default function VideoFilters({
  filters,
  toggleFilter,
}: Props) {
  const filterButtons = filtersList.map((filter) => {
    // give active filters underline
    const activeClass = filters.includes(filter)

    const btnClass = cn(
      style.filter,
      activeClass && style.active,
    )

    return (
      <a
        key={filter}
        onClick={() => toggleFilter(filter)}
        className={btnClass}
      >
        <span>{filter}</span>
      </a>
    )
  })

  return (
    <div className={style.filter_wrap}>{filterButtons}</div>
  )
}

import React from 'react'
import { CSSTransition } from 'react-transition-group'

import Loading from '../Loading'
import VideoPage from '../VideoPage'
import GridPage from '../GridPage'

// import { testImages } from './testImages'

import './style.css'

const initialState = {
  images: [] as string[],
  showVideo: false,
  showGrid: false,
  loading: true,
}

// const testState = {
//   images: [],
//   showVideo: false,
//   showGrid: true,
//   loading: false,
// }

interface State {
  images: string[]
  showVideo: boolean
  showGrid: boolean
  loading: boolean
}

export default class App extends React.Component<
  {},
  State
> {
  state = initialState

  componentDidMount() {
    console.log('start gifstudio')

    this.start()
  }

  start = () => {
    this.setState({
      showVideo: true,
    })
  }

  webcamLoaded = () => {
    this.setState({
      showVideo: true,
      loading: false,
    })
  }

  imagesCaptured = (images: string[]) => {
    this.setState({
      showGrid: true,
      showVideo: false,
      images,
    })
  }

  render() {
    const {
      loading,
      showGrid,
      showVideo,
      images,
    } = this.state

    return (
      <div>
        <CSSTransition
          in={loading}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <Loading />
        </CSSTransition>

        <CSSTransition
          in={showVideo}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <VideoPage
            webcamLoaded={this.webcamLoaded}
            imagesCaptured={this.imagesCaptured}
          />
        </CSSTransition>

        <CSSTransition
          in={showGrid}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <GridPage images={images} />
        </CSSTransition>
      </div>
    )
  }
}

import React, { useRef, useEffect } from 'react'
import cn from 'classnames'
import { startCanvas } from './fx'
import style from './style.css'

interface Props {
  filters: string[]
}

export default function VideoCanvas({ filters }: Props) {
  useEffect(() => {
    startCanvas(filters)
  })

  const canvasEl = useRef(document.createElement('canvas'))
  const mirrorActive = filters.includes('mirror')

  const canvasClass = cn(
    style.canvas,
    mirrorActive && style.flip,
  )

  // const width = ios ? 320 : 640
  // const height = ios ? 640 : 360

  return (
    <canvas
      className={`${canvasClass} canvas`}
      ref={canvasEl}
      width={640}
      height={360}
    />
  )
}

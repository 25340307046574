import React, { MouseEvent } from 'react'
import Spinner from '../Spinner'
import cn from 'classnames'
import style from './style.css'

interface Props {
  icon?: string
  loading?: boolean
  text?: string
  onClick?: (e: MouseEvent) => void
  className?: string
  copyText?: string
  href?: string
  target?: string
  rel?: string
}

export default function ButtonIcon({
  icon,
  loading,
  text,
  onClick,
  className,
  copyText,
  href,
  target,
  rel,
}: Props) {
  return (
    <a
      className={cn(style.btn, className)}
      onClick={onClick}
      data-clipboard-text={copyText}
      href={href}
      target={target}
      rel={rel}
    >
      <i className={`fa fa-${icon}`} />
      <span>{text}</span>
      {loading && <Spinner />}
    </a>
  )
}

import React from 'react'
import loadingLogo from 'lib/images/loading-logo.svg'
import style from './style.css'

export default function Loading() {
  return (
    <div className={style.loading}>
      <img className={style.img} src={loadingLogo} />
    </div>
  )
}

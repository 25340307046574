import React from 'react'
import style from './style.css'

interface Props {
  children: React.ReactElement[]
}

export default function ButtonBar({ children }: Props) {
  const inner = (
    <div className={style.grid}>
      <div className={style.left}>{children[0]}</div>
      <div className={style.center}>{children[1]}</div>
      <div className={style.right}>{children[2]}</div>
    </div>
  )
  return <div className={style.bar}>{inner}</div>
}

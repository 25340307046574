import React, { MouseEvent } from 'react'
import download from 'downloadjs'
import ButtonIcon from '../ButtonIcon'

interface Props {
  id?: string
  data?: string
}

export default function ButtonDownload({
  id,
  data,
}: Props) {
  const url = `https://s3.amazonaws.com/www.gifstudio.me/generated/${id}.gif`

  function downloadGif(e: MouseEvent) {
    e.preventDefault()

    id ? download(url) : download(data, 'GifStudio.gif')
  }

  return (
    <ButtonIcon
      onClick={(e) => downloadGif(e)}
      text="Save Gif"
      icon="download"
    />
  )
}

import React, { useState } from 'react'
import copy from 'clipboard-copy'
import ButtonIcon from '../ButtonIcon'

interface Props {
  copyText: string
}

export default function ButtonCopy({ copyText }: Props) {
  const [copied, setCopied] = useState(false)

  function copyLink() {
    const success = copy(copyText)

    if (success) {
      setCopied(true)
      setTimeout(() => setCopied(false), 1000)
    }
  }

  const text = copied ? 'Copied link!' : 'Copy Link'

  return (
    <ButtonIcon
      onClick={copyLink}
      text={text}
      icon="link"
      className="copy-btn"
    />
  )
}

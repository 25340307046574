import React from 'react'
import ButtonCircle from '../ButtonCircle'

interface Props {
  handleClick: () => void
}

export default function ButtonStart({
  handleClick,
}: Props) {
  return (
    <ButtonCircle onClick={handleClick} icon="camera" />
  )
}

import React, { useEffect } from 'react'
import { setupFacebook, setupTwitter } from './setup'
import ButtonIcon from '../ButtonIcon'
import style from './style.css'

interface Props {
  gifPage?: boolean
}

export default function SocialButtons({ gifPage }: Props) {
  useEffect(() => {
    setupTwitter()
    setupFacebook()
  })

  const tweetText =
    'Check out www.gifstudio.me by @infiniteloopco'
  const shareText =
    'This gif was made with www.gifstudio.me by @infiniteloopco'
  const hashtags = 'gifstudio, infiniteloopco'

  const tweetGif = (
    <ButtonIcon
      icon="twitter"
      text="Tweet"
      href={`https://twitter.com/intent/tweet?text=${shareText}&hashtags=${hashtags}&url=${window.location.href}`}
    />
  )

  const tweetLink = (
    <ButtonIcon
      icon="twitter"
      text="Tweet"
      href={`https://twitter.com/intent/tweet?text=${tweetText}&hashtags=${hashtags}&url=https://www.gifstudio.me`}
    />
  )

  const tweetBtn = gifPage ? tweetGif : tweetLink

  const fbLink = (
    <div
      data-href="https://www.gifstudio.me"
      data-layout="link"
      data-mobile-iframe="true"
    >
      <ButtonIcon
        text="share"
        icon="facebook"
        className="fb-xfbml-parse-ignore"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.gifstudio.me%2F&amp;src=sdkpreparse"
      />
    </div>
  )

  return (
    <div className={style.social}>
      {tweetBtn}
      {fbLink}
    </div>
  )
}

import React from 'react'
import style from './style.css'

interface Props {
  onClick: () => void
  icon: string
}

export default function ButtonCircle({
  onClick,
  icon,
}: Props) {
  return (
    <div className={style.outer}>
      <a className={style.start} onClick={onClick}>
        <i className={`fa fa-${icon}`} />
      </a>
    </div>
  )
}

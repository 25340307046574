import * as React from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom'

import App from './components/App'
import Browser from './components/Browser'
import GifPage from './components/GifPage'

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={App} />
        <Route path="/browser" exact component={Browser} />
        <Route path="/:id" component={GifPage} />
      </Switch>
    </BrowserRouter>
  )
}
